import * as React from "react";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";


export default function MainGal() {
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/gallery`;
        navigate(path);
    }
    return (
        <>
            <div className=' w-full m-auto bg-joinus4 bg-cover  bg-center bg-no-repeat bg-fixed '>
                <div className='bg-[#10426b] bg-opacity-40 '>
                    <div className=' w-full h-[350px]  flex flex-col m-auto justify-center items-center bg-gradient-to-b from-[#10426b] to-transparent 
                '>
                        <Fade bottom>
                            <h1 className='react-reveal text-3xl sm:text-5xl font-bold tracking-wide text-center text-[#f1f8ff]  pt-4  '>Gallery </h1>
                        </Fade>
                    </div>
                </div>
            </div>
            <div onClick={routeChange} className="cursor-pointer grid grid-cols-5 grid-rows-8 gap-4 h-[400px] mx-32 mt-12 pb-32 ">
                <div className="col-span-2 row-span-4    bg-gal11 bg-cover bg-center rounded-md hover:rounded-md
                hover:bg-black  hover:scale-105 hover:transition-transform hover:ease-in-out hover:duration-500   duration-500 hover:bg-opacity-40 
                ">
                    <div className="w-full h-full hover:bg-gradient-to-b from-[#10426b] to-transparent -z-10 hover:bg-opacity-50 hover:scale-100 hover:ease-in-out hover:duration-500  duration-500 hover:rounded-md  ease-in-out transition ">
                        <div className="w-full h-full flex flex-col opacity-100 hover:opacity-100
                        hover:scale-150 hover:transition-transform hover:ease-in-out hover:duration-500   duration-500 text-white  font-poppins text-lg
                        ">
                            <h1 className=" text-xl font-bold text-[#f7fbff]  m-auto visible group-hover:invisible transform ease-in-out duration-500 group-hover:scale-0 group-hover:opacity-0">Townhall Meetup - 2023</h1>
                        </div>
                        {/* text-2xl font-bold text-[#10426b]  m-auto visible group-hover:invisible transform ease-in-out duration-500 group-hover:scale-0 group-hover:opacity-0  */}
                    </div>
                </div>

            </div>

        </>
    );
}