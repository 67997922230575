import React from 'react'
import Fade from 'react-reveal/Fade';

function Team() {
    return (
        <div>
            <section class="text-gray-600 body-font mb-24">
                <div class=" mx-auto">


                    <div className=' mb-16 w-full m-auto bg-team2 bg-cover  bg-center bg-no-repeat bg-fixed '>
                        <div className='bg-[#10426b] bg-opacity-40 '>
                            <div className=' w-full h-[500px]  
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b] to-transparent 
                '>
                                <Fade bottom>
                                    <h1 className='container text-3xl sm:text-5xl font-bold tracking-wide text-center text-white  pt-10 pb-4 '>TASPro Team </h1>
                                </Fade>
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-wrap justify-center -m-4">
                        <div class="p-4 lg:w-1/4 md:w-1/2">
                            <div class="h-full flex flex-col items-center text-center shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                <img alt="team" class="flex-shrink-0 rounded-lg w-full h-44 object-contain object-center mb-4" src="/images/team/Raj.jpg" />
                                <div class="w-full">
                                    <h2 class="title-font font-medium text-lg text-gray-900">S. Rajasekharan</h2>
                                    <h3 class="text-gray-500 mb-3">IT Technology Partner</h3>
                                    <p class="mb-4">M.Bus.Sys (Monash), MCA, CBAP.</p>

                                </div>
                            </div>
                        </div>
                        <div class="p-4 lg:w-1/4 md:w-1/2">
                            <div class="h-full flex flex-col items-center text-center  shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                <img alt="team" class="flex-shrink-0 rounded-lg w-full h-44 object-contain object-center mb-4" src="/images/team/sathish.jpeg" />
                                <div class="w-full">
                                    <h2 class="title-font font-medium text-lg text-gray-900">Sathish Ramadoss</h2>
                                    <h3 class="text-gray-500 mb-3">Principal Architect</h3>
                                    <p class="mb-4">B.Eng, MBA, TOGAF®, CBAP®</p>

                                </div>
                            </div>
                        </div>
                        <div class="p-4 lg:w-1/4 md:w-1/2">
                            <div class="h-full flex flex-col items-center text-center shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                <img alt="team" class="flex-shrink-0 rounded-lg w-full h-44 object-contain object-center mb-4" src="/images/team/S_vel.jpg" />
                                <div class="w-full">
                                    <h2 class="title-font font-medium text-lg text-gray-900">K. Saravanavel</h2>
                                    <h3 class="text-gray-500 mb-3">Lead Solution Architect</h3>
                                    <p class="mb-4">MCA, Java Certified, Certified Ethical Hacker.</p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Team