import LightGallery from 'lightgallery/react';
import React from 'react';
// import styles
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { townhall2023 } from '../asset/Townhall2023';

function Gallery2() {

    const gallery = townhall2023;

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    const onBeforeSlide = (detail) => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    return (
        <>
            <div className='w-[850px]  bg-[#d84545] pl-28 pr-28'>
                <div className='flex w-[850px]  justify-between flex-wrap gap-3 bg-[#2f2fb3] my-12'>
                    <div className=" w-[850px]  rounded-lg flex "  >
                        <LightGallery
                            onInit={onInit}
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            onBeforeSlide={onBeforeSlide}
                            className="flex w-[850px]"
                        >

                            {gallery?.map((img) => {

                                return (
                                    <div className='flex w-full'>

                                        <a href={img.src} className='w-[300px] flex '>
                                            <div className='w-[700px] bg-orange-400 flex'>

                                                <img alt={img.id} src={img.thumb} className='object-contain  ' />
                                            </div>
                                        </a>


                                    </div>
                                )
                            })}

                        </LightGallery>
                    </div>




                </div>
            </div>
        </>
    );
}


export default Gallery2