import { BoltIcon, ChatBubbleBottomCenterTextIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'
import HowWhyWhat from "./HowWhyWhat"
const features = [
  {
    name: 'Best in Class',
    description:
      'Why/ What/ How – Business and Technology At TASPro, we strive to understand ‘Why’ organization exists and ‘What’ they do to meet the ‘Why’. Technology is a key enabler to bridge the gap between ‘Why’ and ‘What’.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Why',
    description:
      'At TASPro we believe in business empowerment through technology. Through our exclusive and extensive software development experience we have gained purposeful business insights into conceptualising and implementing business minded solutions that are tangible, scalable and robust.',
    icon: ScaleIcon,
  },
  {
    name: 'What',
    description:
      'Business systems design & development, Business Process Improvements, Back office IT & helpdesk support, Digital transformation and Technology Modernisation',
    icon: BoltIcon,
  },
  {
    name: 'How',
    description:
      'We conceptualise,design,implement and support. Our IT solutions are backed by industry knowledge and expert IT Team. From a simple App design to Enterprise Systems our solutions are tactical,analytical and strategical. We hold ourselves accountable for our clients technology enablement.',
    icon: ChatBubbleBottomCenterTextIcon,
  },
]

export default function Example() {
  return (

<div>
      


      <HowWhyWhat />

    </div >
  )
}
