import TeamandMethodology from "./TeamandMethodology";

const features = [
  {
    name: "Architecture ",
    description:
      "Solution Architect, Data Architect,  Cloud and Infrastructure Architect.",
  },
  {
    name: "Analysis ",
    description: "Enterprise, Business and Functional Analyst.",
  },
  {
    name: "Implementation ",
    description:
      "Enterprise Application Developers, User Experience designer, Analyst Programmers, Data designers,",
  },
  { name: "Technology", description: "Oracle, Oracle, Sharepoint" },
  {
    name: "Methodology",
    description:
      "API first development, Industry recognised Process and methodologies ",
  },
  {
    name: "Infrastructure management ",
    description: "Data governance and Master Data Management",
  },
];

export default function Example() {
  return (
    <div className=" rounded-xl m-auto
       " >


      {/* <div className="container m-auto font-poppins px-20 pb-16 pt-16 mix-blend-normal">
        <div className="flex ">

          <div className="w-1/2">
            <h2 className="flex justify-end container text-4xl font-bold text-gray-800 sm:text-4xl tracking-wide leading-10 ">
              Team and Methodology
            </h2>
            <p className="mt-4 text-gray-500 leading-9 tracking-wide">
              TASPro professional management team is armed with 40 years of
              collective hands-on technical experience. Since 2000 our
              professional team has worked extensively in major financial services
              projects ranging from Client relationship management systems in
              terms of financial services.
            </p>

            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-[#10426b] leading-8 tracking-wide">
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500 leading-7 tracking-wide">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="w-1/2 justify-center m-auto">
            <img src="/images/business/teamwork.png"
              className=" " alt=""
            />
          </div>
        </div >
      </div > */}
      <TeamandMethodology />
    </div>
  );
}
