import React from 'react'

function Test() {
    return (
        <div className='bg-hero w-full h-24 bg-red-500 bg-opacity-40 ' >
            Image testing
        </div>
    )
}

export default Test