import React from 'react'
import Zoom from 'react-reveal/Zoom';

function Transition() {
    return (
        <div className=' container m-auto flex flex-col lg:flex lg:flex-row justify-center w-11/12 lg:w-[500px] h-fit lg:h-[500px] items-center mt-16 lg:mt-0 bg-transparant gap-16  '>

            <Zoom>
                {/* why */}
                <div className='group relative '>
                    <img src='images/business/searching.png' alt='' className='object-contain object-center w-full h-full  rounded-2xl absolute z-10 group-hover:opacity-50 group-hover:scale-75 transform duration-500 ease-in-out ' />
                    <div className='w-72  h-72 bg-white    justify-start items-start   flex flex-col relative rounded-xl shadow-md rotate-45 group-hover:h-80 
                 group-hover:scale-110 group-hover:-translate-y-3 ease-in-out duration-500 group-hover:bg-[#10426b] group-hover:bg-opacity-80 group-hover:shadow-2xl group-hover:drop-shadow-sm  group-hover:-rotate-0 '>
                        <h1 className='text-2xl font-bold text-[#10426b] absolute -rotate-45 top-6 left-3
                    m-auto 
                    visible group-hover:invisible transform ease-in-out duration-500 group-hover:scale-0 group-hover:opacity-0 '>why</h1>
                        <p className='text-sm font-normal text-white p-6 tracking-wide leading-6 invisiable group-hover:visible transform ease-in-out duration-500 scale-0 group-hover:scale-100 opacity-0 group-hover:opacity-100 '>At TASPro we believe in business empowerment through technology. Through our exclusive and extensive software development experience we have gained purposeful business insights into conceptualising and implementing business minded solutions that are tangible, scalable and robust.</p>
                    </div>
                </div>
                {/* what */}
                <div className='group relative'>
                    <img src='images/business/question-girl.png' alt='' className='object-contain object-center w-full h-full  rounded-2xl absolute z-10 group-hover:opacity-50 group-hover:scale-75 transform duration-500 ease-in-out ' />
                    <div className='w-72  h-72 bg-white   justify-start items-start   flex flex-col relative rounded-xl shadow-md  rotate-45 group-hover:h-80 
                 group-hover:scale-110 group-hover:-translate-y-3 ease-in-out duration-500 group-hover:bg-[#10426b] group-hover:bg-opacity-80 group-hover:shadow-2xl group-hover:drop-shadow-sm  group-hover:-rotate-0 '>
                        <h1 className='text-2xl font-bold text-[#10426b] absolute -rotate-45 top-6 left-3
                    m-auto 
                    visible group-hover:invisible transform ease-in-out duration-500 group-hover:scale-0 group-hover:opacity-0 '>what</h1>
                        <p className='text-sm font-normal text-white p-6 tracking-wide leading-6 invisiable group-hover:visible transform ease-in-out duration-500 scale-0 group-hover:scale-100 opacity-0 group-hover:opacity-100 '>Business systems design & development, Business Process Improvements, Back office IT & helpdesk support, Digital transformation and Technology Modernisation.</p>
                    </div>
                </div>
                {/* who */}
                <div className='group relative'>
                    <img src='images/business/idea-women.png' alt='' className='object-contain object-center w-full h-full  rounded-2xl absolute z-10 group-hover:opacity-50 group-hover:scale-75 transform duration-500 ease-in-out ' />
                    <div className='w-72  h-72 bg-white   justify-start items-start   flex flex-col relative rounded-xl shadow-md rotate-45 group-hover:h-80 
                 group-hover:scale-110 group-hover:-translate-y-3 ease-in-out duration-500 group-hover:bg-[#10426b] group-hover:bg-opacity-80 group-hover:shadow-2xl group-hover:drop-shadow-sm  group-hover:-rotate-0 '>
                        <h1 className='text-2xl font-bold text-[#10426b] absolute -rotate-45 top-6 left-3
                    m-auto 
                    visible group-hover:invisible transform ease-in-out duration-500 group-hover:scale-0 group-hover:opacity-0 '>Who</h1>
                        <p className='text-sm font-normal text-white p-6 tracking-wide leading-6 invisiable group-hover:visible transform ease-in-out duration-500 scale-0 group-hover:scale-100 opacity-0 group-hover:opacity-100 '>We conceptualise, design, implement and support. Our IT solutions are backed by industry knowledge and expert IT Team. From a simple App design to Enterprise Systems our solutions are tactical, analytical and strategical. We hold ourselves accountable for our clients technology enablement.</p>
                    </div>
                </div>
            </Zoom>

        </div>
    )
}

export default Transition