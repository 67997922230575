import React from 'react'
// import Hero2 from '../components/Hero2'
import FeatureSections from '../components/FeatureSections'
// import ProductFeatures from '../components/ProductFeatures'
import EndToEndITService from '../components/EndToEndITService'
import SolutionandService from '../components/SolutionandService'
import Whatwedo from '../components/Whatwedo'
// import Cards from '../components/Cards'
// import Companies from '../components/Companies'
import Hero from '../components/Hero'
// import Wave from '../components/Wave'
import Cards2 from '../components/Cards2'
import TeamandMethodology from '../components/TeamandMethodology'


function Home() {
    return (
        <div className="bg-white ">
            <Hero />
            {/* <Companies /> */}
            <Whatwedo />
            {/* <Cards /> */}
            <Cards2 />
            <FeatureSections />
            <SolutionandService />
            {/* <ProductFeatures /> */}
            <TeamandMethodology />
            <EndToEndITService />


        </div>
    )
}

export default Home