import React from 'react'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
function TeamandMethodology() {
    return (
        <div>
            <div className=' m-auto bg-team_1 bg-cover bg-left bg-no-repeat bg-fixed w-full '>
                <div className='bg-[#10426b] bg-gradient-to-b from-[#10426ba1] to-transparent  bg-opacity-20 pb-24 pt-24  '>
                    <div className='container w-full h-fit 
                flex flex-col m-auto justify-center items-center 
                
                '>
                        <Fade bottom>

                            <h1 className='text-3xl sm:text-5xl font-bold tracking-wide text-center text-[#f1f8ff]  pt-4 '>Team and Methodology</h1>
                        </Fade>
                        <Fade top>

                            <p className='font-poppins text-base  font-normal text-center text-[#ececec] p-4 sm:px-16 leading-8 sm:leading-8'>TASPro professional management team is armed with 40 years of collective hands-on technical experience. Since 2000 our professional team has worked extensively in major financial services projects ranging from Client relationship management systems in terms of financial services.</p>
                        </Fade>
                    </div>
                    <div>
                        <div className='container m-auto  w-full max-h-full     '>
                            <Zoom cascade>
                                <div className='block sm:flex m-auto -mt-2 p-2 space-y-2 sm:space-y-0 gap-4 sm:mx-4 mx-10 '>
                                    <div className='w-full h-[130px] flex flex-col m-auto bg-[#e9eef1]  rounded-xl p-4 shadow-xl'>
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b]'>Architecture</h1>
                                        <p className='font-poppins text-sm font-light text-gray-800'>Solution Architect, Data Architect, Cloud and Infrastructure Architect.</p>
                                    </div>
                                    <div className='w-full h-[130px] flex flex-col m-auto bg-[#e9eef1]  rounded-xl p-4 shadow-xl'>
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b]'>Analysis</h1>
                                        <p className='font-poppins text-sm font-light text-gray-800'>Enterprise, Business and Functional Analyst.</p>
                                    </div>
                                    <div className='w-full h-[130px] flex flex-col m-auto bg-[#e9eef1]  rounded-xl p-4 shadow-xl'>
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b]'>Implementation</h1>
                                        <p className='font-poppins text-sm font-light text-gray-800'>Enterprise Application Developers, User Experience designer, Analyst Programmers, Data designers,</p>
                                    </div>
                                </div>
                                <div className='block sm:flex m-auto -mt-2 p-2 space-y-2 sm:space-y-0 gap-4 sm:mx-4 mx-10'>
                                    <div className='w-full h-[130px] flex flex-col m-auto bg-[#e9eef1]  rounded-xl p-4 shadow-xl'>
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b]'>Technology</h1>
                                        <p className='font-poppins text-sm font-light text-gray-800'>Oracle, DevOps, Sharepoint.</p>
                                    </div>
                                    <div className='w-full h-[130px] flex flex-col m-auto bg-[#e9eef1]  rounded-xl p-4 shadow-xl'>
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b]'>Methodology</h1>
                                        <p className='font-poppins text-sm font-light text-gray-800'>API first development, Industry recognised Process and methodologies.</p>
                                    </div>
                                    <div className='w-full h-[130px] flex flex-col m-auto bg-[#e9eef1]  rounded-xl p-4 shadow-xl'>
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b]'>Infrastructure management</h1>
                                        <p className='font-poppins text-sm font-light text-gray-800'>Data governance and Master Data Management.</p>
                                    </div>
                                </div>
                            </Zoom>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamandMethodology


