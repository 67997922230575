import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

function Whatwedo() {
  return (
    <div className=''>
      <div className='container block lg:flex m-auto px-20 -mb-5 relative  rounded-xl mt-16   bg-bluewave5 
       bg-cover
      shadow-[10px_15px_80px_-15px_rgba(0,0,0,0.3)] '>
        <Fade left cascade>
          <div className='flex flex-col w-full m-auto items-center lg:items-start pt-10 lg:pt-0 lg:w-3/6    '
          >
            <h1 className='text-3xl sm:text-5xl   mb-2 font-bold text-[#10426b]  tracking-wide w-full     '>What we do?</h1>

            <p className='font-poppins text-base   leading-8 sm:leading-8 tracking-wide text-neutral-500 font-normal  '>Concentrating primarily on financial services industry, TASPro Technologies is laser focused on delivering purpose built IT systems for financial advisory services and fintech firms in APAC region.</p>

            <Fade left>
              <div className='flex '>
                <button className='  w-[130px] h-[45px] md:w-[140px] md:h-[55px] bg-[#10426b] text-white rounded-md  text-base sm:text-xl font-bold mt-2'>
                  <a href="/Whatwedopage" className="">
                    Show more
                  </a>
                </button>
                <ArrowLeftIcon className='w-[30px] ml-1 mt-2  rotate-180 text-[#10426b] ' />
              </div>
            </Fade>
          </div>
        </Fade>
        <Zoom >
          <div className='w-full lg:w-3/6 bg-transparent'>
            <img src="/images/business/business2.png" className='w-full h-full bg-transparent  ' alt='' />
          </div>
        </Zoom>
      </div>
    </div >

  )
}

export default Whatwedo