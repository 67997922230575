import React from 'react'
import Fade from 'react-reveal/Fade';
function AboutUs() {



    return (
        <div>
            <div>
                <div className=' m-auto bg-aboutus4 bg-cover bg-left bg-no-repeat bg-fixed '>
                    <div className='bg-[#10426b] bg-opacity-40 pb-6'>
                        <div className=' w-full max-h-fit md:h-[500px] 
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b] to-transparent 
                '>
                            <Fade bottom>

                                <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-white  pt-10 pb-4 '>About us</h1>
                                <div className='w-full md:w-4/5 mx-5 px-5 text-center space-y-4'>
                                    <p className='font-poppins text-base  font-normal  text-[#ececec]  leading-8 sm:leading-8'>TASPro Technologies has been implementing IT solutions for financial advisory services for over 19 years.</p>
                                    <p className='font-poppins text-base  font-normal  text-[#ececec]  leading-8 sm:leading-8'>TASPro Technologies is  a business focused technology partner and foster business excellence through operational and informational data driven technologies.</p>
                                    <p className='font-poppins text-base  font-normal  text-[#ececec]  leading-8 sm:leading-8'>TASPro has extensive experience and wealth of industry knowledge in key business functions such as customer relationship, life and general policy handling, advisers and staff maintenance, commission calculations and investment portfolio management.</p>
                                </div>
                            </Fade>

                        </div>

                    </div>
                </div>
            </div>

            <div className='container px-5 py-10 flex flex-wrap gap-5 m-auto justify-center mt-10'>
                <div className='w-[450px] h-[600px]  rounded-md p-2 bg-white shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70'>
                    <img src='/images/aboutus/vision3.png' alt='' className='w-4/6 m-auto' />
                    <h1 className='text-base sm:text-xl font-bold text-[#10426b] px-4 py-2'>Vision – (Why and How)</h1>
                    <p className='font-poppins text-sm font-light text-gray-800 px-4 text-start leading-8 sm:leading-8'>Create competitive advantage to our clients through information technology by improving business productivity and assist business excellence in customer management services</p>
                </div>
                <div className='w-[450px] h-[600px] rounded-md p-2 bg-white shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70'>
                    <img src='/images/aboutus/aboutus3.png' alt='' className='' />
                    <h1 className='text-base sm:text-xl font-bold text-[#10426b] px-4 py-2'>Mission - (What and Who)</h1>
                    <p className='font-poppins text-sm font-light text-gray-800 px-4 text-start leading-8 sm:leading-8'>Be a business focused technology partner to our clients and foster business excellence through operational and informational data driven technologies.<br />Keep organisational need first and ensure value of technology and solution is measured through business success.</p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs