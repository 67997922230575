import React from 'react'
import { GrMultiple } from "react-icons/gr";
import { GiTechnoHeart } from "react-icons/gi";
import { AiOutlineRise } from "react-icons/ai";
import { SiBuildkite } from "react-icons/si";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
function EndToEndITService() {
    return (
        <div className='container m-auto   rounded-xl px-2  mb-20 mt-16 font-poppins '>

            {/* <div className='flex  m-auto w-full'>

                <div className='w-1/2  m-auto '>
                    <h1 className='text-3xl font-bold text-gray-800 sm:text-4xl tracking-wide'>End to End IT Services</h1>
                    <p className='mt-4 text-gray-500 leading-8 tracking-wide'>At TASPro, we strongly focus on business outcome, thus we treat technology as a value driver rather as constraint. We provide technical solution that are customized and specific to the business needs. Our business focused technology ranges from Enterprise Business System,application customer experience & engagement, Data management & practices, Decision support systems, Legislation & compliance, Cyber security and Virtual desktop support</p>
                    <h1 className='mt-4 text-2xl text-gray-800  font-semibold leading-8 tracking-wide'>KeyFocus Areas</h1>
                    <ul className='mt-4 text-gray-500 leading-8 tracking-wide list-disc pl-4'>
                        <li>Multitenant applications - Customer Relations Management, Insurance administration and Advisory management.</li>
                        <li>Digital Transformation.</li>
                        <li>Business systems development and support.</li>
                        <li>IT Infrastructure and virtual desktop support.</li>
                    </ul>
                </div>
                <div className='w-1/2  '>
                    <img src='/images/business/endtoenditservice.png' alt='' />
                </div>
            </div> */}


            <div className='w-full h-full p-2 lg:p-6 '>
                <div className='w-full h-1/4'>
                    <Fade bottom>
                        <h1 className='text-3xl sm:text-5xl font-bold text-[#10426b] text-center pb-5 tracking-wide'>End to End IT Services</h1>
                    </Fade>
                    <Fade top>
                        <p className='font-poppins text-base font-normal text-neutral-500 tracking-wide leading-8 sm:leading-8'>At TASPro, we strongly focus on business outcome, thus we treat technology as a value driver rather as constraint. We provide technical solution that are customized and specific to the business needs. Our business focused technology ranges from Enterprise Business System,application customer experience & engagement, Data management & practices, Decision support systems, Legislation & compliance, Cyber security and Virtual desktop support.</p>
                    </Fade>
                </div>
                <div className='w-full h-3/4 flex flex-col-reverse   lg:flex lg:flex-row'>


                    <div className='w-full lg:w-1/2 h-full'>
                        <Zoom>
                            <h1 className='text-lg font-normal text-[#10426b] pt-6'>KeyFocus Areas</h1>
                        </Zoom>
                        <Fade left cascade>
                            <div className='w-full h-fit p-4 mt-8 space-y-4'>
                                <div className='w-full h-[180px] sm:h-[100px] bg-white items-center flex justify-start rounded-xl shadow-md  '>
                                    <GrMultiple className='w-16 p-3 rounded-r-xl shadow-lg drop-shadow-md text-xl  h-14 bg-blue-100' />
                                    <h1 className='font-poppins  font-light text-neutral-500 text-base p-4'>Multitenant applications - Customer Relations Management, Insurance administration, Advisory Management & B2B Services.</h1>
                                </div>
                                <div className='w-full h-[100px] bg-white items-center flex justify-start rounded-xl shadow-md  '>
                                    <GiTechnoHeart className='p-4 rounded-r-xl shadow-lg drop-shadow-md text-xl w-16 h-14 bg-blue-100' />
                                    <h1 className='font-poppins font-light text-neutral-500 text-base p-4'>Digital Transformation.</h1>
                                </div>
                                <div className='w-full h-[100px] bg-white items-center flex justify-start rounded-xl shadow-md  '>
                                    <AiOutlineRise className='p-4 rounded-r-xl shadow-lg drop-shadow-md text-xl w-16 h-14 bg-blue-100' />
                                    <h1 className='font-poppins font-light text-neutral-500 text-base p-4'>Business systems development and support.</h1>
                                </div>
                                <div className='w-full h-[100px] bg-white items-center flex justify-start rounded-xl shadow-md  '>
                                    <SiBuildkite className='p-4 rounded-r-xl shadow-lg drop-shadow-md text-xl w-16 h-14 bg-blue-100' />
                                    <h1 className='font-poppins font-light text-neutral-500 text-base p-4'>IT Infrastructure and virtual desktop support.</h1>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <Zoom>
                        <div className='w-full lg:w-1/2 h-full flex justify-center m-auto'>
                            <img src='/images/business/endtoenditservice-2.png' alt='' />
                        </div>
                    </Zoom>
                </div>
            </div>
        </div>
    )
}

export default EndToEndITService