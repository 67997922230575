import React, { useEffect, useState } from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';

const Footer = () => {

  const [currentDate, setCurrentDate] = useState(new Date().getFullYear());
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current date every second (you can adjust the interval as needed)
      const presentYr = new Date().getFullYear();
      setCurrentDate(presentYr);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once (on mount)
  console.log("currentDate----->" + currentDate)
  return (
    <>
      <div className='bg-[#10426b]'>
        <div className='container flex flex-col items-center pt-16 pb-16 m-auto md:flex md:flex-row md:px-20'>
          <div className="w-full px-4 md:w-1/2 md:px-0 ">
            <h1 className='text-xl font-bold text-white '>TASPro Technologies</h1>
            <p className="pr-0 text-base font-normal leading-8 text-gray-400 font-poppins sm:leading-8 sm:pr-4 md:pr-8 xl:pr-16">Since 2003, providing a business focused, professional and practical end to end technology solutions to ease business problems. Our solutions are tactical, analytical, strategical with focus on one primary goal.</p>
            <div className='flex justify-start gap-2 md:w-[75%] my-6 text-white'>

              <a href="https://www.linkedin.com/in/taspro-technologies-pvt-ltd/" target="_blank" rel="noreferrer noopener"><FaLinkedin size={30} /></a>
              <a href="https://www.facebook.com/people/TASPro-Technologies-India-Private-Limited/100081046253888/?sk=about" target="_blank" rel="noreferrer noopener"><FaFacebook size={30} /></a>
              <a href="https://www.instagram.com/tasprotech/" target="_blank" rel="noreferrer noopener"><FaInstagram size={30} /></a>

            </div>
          </div>


          <div className='flex justify-around w-full px-4 space-x-8 md:w-1/2 md:space-x-24 '>
            <div className="w-1/2">
              <h6 className='font-medium text-gray-100 '>Quick Links</h6>
              <ul>
                <li className='py-2 text-sm'>
                  <a href="/" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    Home
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/approchprincipal" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    Approach & Principal
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/teammethodology" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    Team & Methodology
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/endtoenditsolution" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    End to End IT Solution
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/solutionservices" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    Solution Services
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-1/2">
              <h6 className='font-medium text-gray-100'>Company</h6>
              <ul>
                <li className='py-2 text-sm'>
                  <a href="/aboutus" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    About
                  </a>
                </li>

                <li className='py-2 text-sm'>
                  <a href="/whyjoinus" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    Why Join Us
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/contact" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    Contact
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/team" className="relative text-base font-normal text-gray-400 font-poppins hover:text-white top-2">
                    TASPro Team
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className=' border-gray-600 text-xs w-full h-[30px] text-white font-poppins font-medium text-center '>
          <h1 className='text-base font-normal text-gray-100 font-poppins hover:text-white '>Copyright © {currentDate.toLocaleString().replace(/,/g, '')} . TASPro Technologies</h1>
        </div>
      </div>
    </>
  );
};

export default Footer;
