import React from 'react'
import Fade from 'react-reveal/Fade';

function ApprochPrincipal() {
    return (
        <div className='relative bg-transparant'>

            <section class="">
                <div class="   m-auto ">


                    <div className=' mb-16 w-full m-auto bg-approach2 bg-cover  bg-center bg-no-repeat bg-fixed  '>
                        <div className=' bg-[#10426b] bg-opacity-40 '>
                            <div className=' w-full h-full sm:h-[500px]  
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b8b] to-transparent 
                pb-16 sm:pb-0
                '>
                                <Fade bottom>
                                    <h1 className='container  text-3xl sm:text-5xl  font-bold tracking-normal text-center text-white  pt-10 pb-4 '>TASPro Business & Delivery approach </h1>

                                    <p className='container font-poppins text-center px-2 md:px-24 text-base tracking-wide font-normal  text-gray-200 leading-8 sm:leading-8 '>TASPro focus is mainly on small and medium firms who have limited budget for technology drivers.<br /> At TASPro we employ our dedicated business and technology team to assess the business need, analyse information and use innovative and cost effective technology to provide our clients with purpose fit solutions, as well as value-added services.
                                        We employ both traditional and agile methodologies in delivery model and provide dedicated resources for
                                    </p>

                                </Fade>
                            </div>
                        </div>
                    </div>








                    <div class="flex flex-wrap -m-4 mt-5 p-4 mx-4  ">
                        <div class="xl:w-1/4 md:w-1/2 w-full p-4 rounded-md shadow-lg space-x-4 space-y-4 bg-white  ">
                            <div class="bg-white  p-6 rounded-lg">
                                <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/approchPrinciple/top/business-analysis.jpg" alt="content" />
                                <h3 class="text-base sm:text-xl font-bold text-[#10426b] text-center">Business Analysis </h3>
                            </div>
                        </div>
                        <div class="xl:w-1/4 md:w-1/2 w-full p-4 rounded-md shadow-lg space-x-4 space-y-4 bg-white ">
                            <div class="bg-white  p-6 rounded-lg">
                                <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/approchPrinciple/top/project-management.jpg" alt="content" />
                                <h3 class="text-base sm:text-xl font-bold text-[#10426b] text-center">Project Management and communication </h3>
                            </div>
                        </div>
                        <div class="xl:w-1/4 md:w-1/2 w-full p-4 rounded-md shadow-lg space-x-4 space-y-4 bg-white ">
                            <div class="bg-white  p-6 rounded-lg">
                                <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/approchPrinciple/top/app-development.jpg" alt="content" />
                                <h3 class="text-base sm:text-xl font-bold text-[#10426b] text-center">Application development, testing and implementation </h3>
                            </div>
                        </div>
                        <div class="xl:w-1/4 md:w-1/2 w-full p-4 rounded-md shadow-lg space-x-4 space-y-4 bg-white ">
                            <div class="bg-white  p-6 rounded-lg">
                                <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/approchPrinciple/top/Organisational Change Management.jpg" alt="content" />
                                <h3 class="text-base sm:text-xl font-bold text-[#10426b] text-center">Organisational Change Management </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section >
                <div className='container  m-auto  rounded-3xl mt-12 mb-12 '>
                    <h1 className="text-3xl sm:text-5xl font-bold tracking-wide  text-[#10426b] text-center mb-3">TASPro IT Competency</h1>
                    <p className="font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8 flex flex-col text-center my-6">TASPro IT have worked extensively in services sector with primary focus on financial domain.</p>

                </div>
                <div className='flex flex-wrap gap-y-8 justify-around container  m-auto mb-14'>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '>Automated Business process solutions </h1>
                    </div>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '>Registry and management systems such as Insurance and Investment advisory systems</h1>
                    </div>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '>Client relationship management system</h1>
                    </div>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '>Financial planning applications</h1>
                    </div>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '>Business Systems Development and Maintenance</h1>
                    </div>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '>Multitenant applications</h1>
                    </div>
                    <div className='w-96 h-28 p-4 bg-[#f5faff] flex items-center justify-center rounded-md shadow-md '>
                        <h1 className='text-sm sm:text-base font-bold text-[#10426b] text-center '> IT Infrastructure (Hardware and Software) Implementation and Maintenance</h1>
                    </div>
                </div>
            </section>

            <section class="text-gray-800  body-font bg-[#f5faff]">
                <div class="container px-5 py-24 mx-auto flex flex-wrap">
                    <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-[#10426b] text-center mt-0 mb-6'>Our Application Development Principals  </h1>
                    <div class="flex flex-wrap w-full">
                        <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">ONE</h2>
                                    <p class="font-poppins leading-relaxed">Provide a ‘level playing field’ for SME .</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">TWO</h2>
                                    <p class="font-poppins leading-relaxed">Significantly improve document submission turnaround time by 60%.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">THREE</h2>
                                    <p class="font-poppins leading-relaxed">Reduce manual errors in  data processing .</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">FOUR</h2>
                                    <p class="font-poppins leading-relaxed">Seamless integration with other applications.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">FIVE</h2>
                                    <p class="font-poppins leading-relaxed">Embrace digital transformation.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">SIX</h2>
                                    <p class="font-poppins leading-relaxed">Meet stringent  compliance regulations.</p>
                                </div>
                            </div>
                            <div class="flex relative">
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-[#10426b] inline-flex items-center justify-center text-white relative z-10">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-bold title-font text-base sm:text-xl text-gray-900 mb-1 tracking-wide">SEVEN</h2>
                                    <p class="font-poppins leading-relaxed">Reduce onboarding time to improve customer satisfaction.</p>
                                </div>
                            </div>
                        </div>
                        <img class="lg:w-3/5 md:w-1/2 object-contain object-center rounded-lg md:mt-0 mt-12" src="/images/business/appdevprincipals-2.png" alt="step" />
                    </div>
                </div>
            </section>

        </div>
    )
}

export default ApprochPrincipal