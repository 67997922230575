import React from 'react'
import ProductFeatures from '../components/ProductFeatures'

function TeamMethodology() {
    return (
        <div className='mb-16'>
            {/* <div className='container flex my-10 max-w-full bg-[#10426b] h-32 justify-center m-auto '>
                <h1 className=' text-4xl text-white  m-auto font-semibold tracking-wide'>
                    Team & Methodology
                </h1>
            </div> */}
            <ProductFeatures />
            <section className=''>
                <div className='container m-auto   mt-16'>
                    {/* <h1 className="text-xl font-base mb-10">Following matrix shows some of the current Technology drivers and how TASPro IT address them.</h1> */}
                    <h1 className='text-3xl text-center sm:text-5xl font-bold text-[#10426b] pb-4' >Technology Drivers</h1>

                    <div className="flex flex-col gap-10">
                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold text-center text-[#10426b]'>Core Business Applications and Systems </h1>
                                    <img src="/images/team&methodology/appsDevelopments-2.jpg" className='w-1/3 m-auto flex' alt='' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold  text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>Conceptualise, design, develop and deliver key business systems and applications to effectively run business day to day operations</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold  text-[#10426b]'>TASPro  Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Business needs, Requirements and Data analysis and artefacts. Full stack project development and delivery. Organisational change management. Data migration and support.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b] '>Legislative and Compliance Requirements </h1>
                                    <img alt='' src="/images/team&methodology/Legislative-2.jpg" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>Meet ever changing compliance requirements such as PDPA rules in non-disruptive and business friendly development approach.</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro  Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Compliance needs analysis, gap analysis. IT security and vulnerability assessments, Development.</p>
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b] '>IT Infrastructure - Cloud/ SaaS Adoption</h1>
                                    <img alt='' src="/images/team&methodology/cloudStorage.png" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 '>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>Minimise operational cost and break geographical barriers by reducing cost, mitigating risk and achieving scalability of data base and server capabilities.</p>
                                    </div>
                                    <div className='px-0 sm:px-10 '>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Cloud computing, data migration, cloud service vendor contract and relationship management, SW maintenance contract, connected systems</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b] '>Digital Transformation</h1>
                                    <img src="/images/team&methodology/Digital transformation.png" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>Integration of digital and mobile technology into business IT landscape and develop new capabilities and business models.</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Needs analysis, technology consultation, Vendor engagement, SharePoint and portal development</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b]'>Business Insights and Decision Driven Systems</h1>
                                    <img src="/images/team&methodology/businessInsights.png" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>System to collect, store, assess  data to provide intelligence and empower enterprise business to make better decisions and drive innovation</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Big data, Business Intelligence and Analytics, enterprise reporting.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b]'>Automation and Business Excellence</h1>
                                    <img src="/images/team&methodology/Automation-2.jpg" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>Achieve business efficiency, improve customer satisfaction, drive performance through process automation, eliminate media breaks and data empowerment</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Cloud computing, business process analysis and redesign, Interconnected systems.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b]'>Customer Relationship Management</h1>
                                    <img src="/images/team&methodology/clientManagement.png" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>System to improve customer engagement and management, ideally procured as a cloud offering such as Sales force and MS Dynamics 360</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Solution assessment and consultation, data migration, organisational change management, vendor solution evolution</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='flex flex-col gap-10'>
                            <div className='block sm:flex    rounded-2xl  border-[#10426b] shadow-lg bg-white px-4 py-10'>
                                <div className='block w-full sm:w-1/3 m-auto '>
                                    <h1 className='text-base sm:text-xl font-bold  text-center text-[#10426b]'>IT Back-office Support</h1>
                                    <img src="/images/team&methodology/backendsupport.png" className='w-1/3 m-auto flex' />
                                </div>
                                <div className=" flex flex-col justify-center items-center m-auto w-2/3 gap-10">
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>Synopsis</h1>
                                        <p className='text-sm font-light text-neutral-500'>IT infrastructure support services to optimise  back-office operations through technology driven work process</p>
                                    </div>
                                    <div className='px-0 sm:px-10 w-full'>
                                        <h1 className='text-base sm:text-xl font-bold   text-[#10426b]'>TASPro Solution</h1>
                                        <p className='text-sm font-light text-neutral-500'>Help desk and data processing support, Network administration, Data protection and backup, Email and website administration</p>
                                    </div>
                                </div>
                            </div>
                        </div>



















                    </div>

                </div>
            </section>


            <section class="text-gray-600 body-font">
                <div class="container px-10 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="text-3xl text-center sm:text-5xl font-bold text-[#10426b] pb-4">Our Current Team certifications</h1>

                    </div>
                    <div class="w-full flex flex-wrap m-auto">
                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/microsoft azure.png" />
                                <div class="px-8 py-10 relative z-10 w-full  border-gray-200 bg-white opacity-0  hover:opacity-80 duration-300 hover:scale-105">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center">Microsoft Azure</h1>
                                </div>
                            </div>
                        </div>

                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/Enterprise JAVA Professional.png" />
                                <div class="px-8 py-10 relative z-10 w-full duration-300 hover:scale-105 border-gray-200 bg-white opacity-0 hover:opacity-80">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center">Enterprise JAVA Professional</h1>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/microsoft-ai-enabled-lab.jpg" />
                                <div class="px-8 py-10 relative z-10 w-full duration-300 hover:scale-105 border-gray-200 bg-white opacity-0 hover:opacity-80">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center">Microsoft AI Certified Professional</h1>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/oracle.png" />
                                <div class="px-8 py-10 relative z-10 w-full duration-300 hover:scale-105 border-gray-200 bg-white opacity-0 hover:opacity-80">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center"> Oracle SQL Certified</h1>
                                </div>
                            </div>
                        </div>




                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/cbap certified iiba.png" />
                                <div class="px-8 py-10 relative z-10 w-full duration-300 hover:scale-105 border-gray-200 bg-white opacity-0 hover:opacity-80">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center">Certified Business Analysis Professional (CBAP®)</h1>

                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/pen_certified.png" />
                                <div class="px-8 py-10 relative z-10 w-full duration-300 hover:scale-105 border-gray-200 bg-white opacity-0 hover:opacity-80">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center">TOGAF® Certification | The Open Group</h1>

                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/3 sm:w-1/2 p-4">
                            <div class="flex relative">
                                <img alt="gallery" class="absolute inset-0 w-full h-full object-contain object-center" src="/images/team&methodology/currentTeamCertifications/cdmp.png" />
                                <div class="px-8 py-10 relative z-10 w-full duration-300 hover:scale-105 border-gray-200 bg-white opacity-0 hover:opacity-80">
                                    <h1 class="title-font text-lg font-medium text-gray-900 mb-3 text-center">Certified Data Management Professional</h1>

                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>
        </div>
    )
}

export default TeamMethodology