import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './Pages/AboutUs';
import ApprochPrincipal from './Pages/ApprochPrincipal';
import Contact from './Pages/Contact';
import EndtoEndITSolution from './Pages/EndtoEndITSolution';
import Home from './Pages/Home';
import SocialLinks from './Pages/SocialLinks';
import SolutionServices from './Pages/SolutionServices';
import Team from './Pages/Team';
import TeamMethodology from './Pages/TeamMethodology';
import WhyJoinUs from './Pages/WhyJoinUs';
import './components/style.css';
// import Blog from './Pages/Blog';
import { ScrollToTop } from '../src/components/scroll/ScrollToTop';
import Gallery from './Pages/Gallery';
import Gallery2 from './Pages/Gallery2';
import MainGal from './Pages/MainGal';
import Whatwedopage from './Pages/Whatwedopage';
import Test from './Test';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import TeamandMethodology from './components/TeamandMethodology';
import Transition from './components/Transition';
import './components/style.css';
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/approchprincipal" element={<ApprochPrincipal />} />
          <Route path="/teammethodology" element={<TeamMethodology />} />
          <Route path="/whyjoinus" element={<WhyJoinUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/sociallinks" element={<SocialLinks />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/solutionservices" element={<SolutionServices />} />
          <Route path="/endtoenditsolution" element={<EndtoEndITSolution />} />
          <Route path="/team" element={<Team />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/Whatwedopage" element={<Whatwedopage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/transition" element={<Transition />} />
          <Route path="/teamandmethodology" element={<TeamandMethodology />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery2" element={<Gallery2 />} />
          <Route path="/maingal" element={<MainGal />} />
          {/* <Route path="/gallary" element={<Gallary />} /> */}
          {/* <Route path="/strapi" element={<Strapi />} /> */}
        </Routes>
        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

//  "