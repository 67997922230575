
// import { CheckBadgeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import React from 'react';
import Fade from 'react-reveal/Fade';

function Contact() {
    return (
        <div>
            {/* Address */}

            {/* form */}
            <section class="text-gray-600 body-font relative font-poppins">
                <div className='w-full m-auto bg-fixed bg-center bg-no-repeat bg-cover bg-contact3'>
                    <div className='bg-[#10426b] bg-opacity-40 '>
                        <div className=' w-full h-[500px]  
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b] to-transparent 
                '>
                            <Fade bottom>
                                <h1 className='pt-10 pb-4 text-4xl font-bold tracking-wide text-white '>Contact </h1>
                            </Fade>
                        </div>
                    </div>
                </div>




                {/* contact address & details - 2 */}
                <div className='container flex flex-wrap justify-center w-full gap-6 m-auto my-16 h-fit'>
                    <div className='w-[400px]  h-[640px] bg-white shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70 '>
                        <div className='w-full m-auto h-2/12 '>
                            <img src="/images/flags/india.png" className='w-full rounded-t-xl ' alt="" />
                        </div>
                        <div className='p-4 '>
                            <h1 className="text-base sm:text-xl font-bold text-[#10426b]  ">Address</h1>
                            <p className="text-sm font-light leading-8 text-gray-800 font-poppins sm:leading-8">
                                TASPro Technologies India Private Limited,
                                <br /> 7 / 1, VSK Building, Thondamuthur Road,
                                <br />Vadavalli, Coimbatore,
                                <br />TamilNadu, India - 641 041.
                            </p>
                        </div>
                        <div className='pl-4'>
                            <h1 className="text-base sm:text-xl font-bold text-[#10426b] ">Contact</h1>
                            <p className='text-base'>
                                <div className='flex gap-4 pt-2 leading-10'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-6 h-6 text-blue-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                    </svg>
                                    <a href="tel:+918523904930">(HP) +91 8523904930</a>
                                </div>
                                {/* <div className='flex gap-4 leading-7'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                    </svg>
                                    +65 64933038 (Voip)
                                </div> */}
                                <div className='flex gap-4 leading-7'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-500">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                    </svg>

                                    <a href="mailto:admin.ind@tasproit.com">admin.ind@tasproit.com</a>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className='w-[400px]  h-[640px] bg-white shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70 '>
                        <div className='w-full m-auto h-2/12 '>
                            <img src="/images/flags/Singapore.png" className='w-full rounded-t-xl ' alt="" />
                        </div>
                        <div className='p-4 '>
                            <h1 className="text-base sm:text-xl font-bold text-[#10426b]  ">Address</h1>
                            <p className="text-sm font-light leading-8 text-gray-800 font-poppins sm:leading-8">
                                TASPro IT Solutions Pte Limited,
                                <br />22 Sin Ming Lane,
                                <br /> #06-76 Midview City
                                <br /> Singapore - 573969.
                            </p>
                        </div>
                        <div className='pl-4'>
                            <h1 className="text-base sm:text-xl font-bold text-[#10426b]">Contact</h1>
                            <p className='text-base'>
                                {/* <div className='flex gap-4 leading-10'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-6 h-6 text-blue-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                    </svg>
                                    +65 64933038 (Voip)
                                </div> */}

                                <div className='flex gap-4 pt-2 leading-7'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-500">
                                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                    </svg>

                                    <a href="mailto:admin.sg@tasproit.com">admin.sg@tasproit.com</a>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-12">

                        <p class="text-base sm:text-xl font-bold text-[#10426b]">Feel free to Contact us for any Details.</p>
                    </div>
                    <div class="lg:w-1/2 md:w-2/3 mx-auto">
                        <div class="flex flex-wrap -m-2">
                            <div class="p-2 w-1/2">
                                <div class="relative">
                                    <label htmlFor="name" class="leading-7 text-sm text-gray-600">Name</label>
                                    <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div class="p-2 w-1/2">
                                <div class="relative">
                                    <label htmlFor="email" class="leading-7 text-sm text-gray-600">Email</label>
                                    <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div class="p-2 w-full">
                                <div class="relative">
                                    <label htmlFor="message" class="leading-7 text-sm text-gray-600">Message</label>
                                    <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                                </div>
                            </div>
                            <div class="p-2 w-full">
                                <button class="flex mx-auto text-white bg-[#10426b] border-0 py-2 px-8 focus:outline-none hover:bg-[#335571] rounded text-lg">Submit</button>
                            </div>
                            {/* <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                                <a class="text-blue-500">example@email.com</a>
                                <p class="leading-normal my-5">49 Smith St.
                                    <br />Saint Cloud, MN 56301
                                </p>
                                <span class="inline-flex">
                                    <a class="text-gray-500">
                                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-4 text-gray-500">
                                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-4 text-gray-500">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                        </svg>
                                    </a>
                                    <a class="ml-4 text-gray-500">
                                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                        </svg>
                                    </a>
                                </span>
                            </div> */}
                            {/* Address */}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact