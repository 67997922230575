import { ArrowDownIcon } from '@heroicons/react/24/outline'
import React from 'react'
import Transition from './Transition'
import Fade from 'react-reveal/Fade';

function HowWhyWhat() {
    return (
        <div>


            <section>
                <div className='container w-full flex flex-col h-fit justify-center items-center lg:h-700px m-auto bg-[#10426b0a] rounded-xl mb-16'>
                    <div className='py-10 px-10  '>
                        <Fade bottom>
                            <h1 className='font-bold mb-4 text-center text-3xl sm:text-5xl text-[#10426b] tracking-normal '>Best in class</h1>
                        </Fade>
                        <Fade top>

                            <p className="font-poppins text-base  font-normal text-neutral-500 tracking-wide leading-8 sm:leading-8">Why/ What/ How – Business and Technology At TASPro, we strive to understand ‘Why’ organization exists and ‘What’ they do to meet the ‘Why’. Technology is a key enabler to bridge the gap between ‘Why’ and ‘What’.</p>
                        </Fade>
                        <Transition />
                    </div>



                </div>
            </section>
        </div>
    )
}

export default HowWhyWhat