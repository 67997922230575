import React from 'react'
import { RiFocus3Fill } from "react-icons/ri";
import { MdComputer } from "react-icons/md";
import { TbCloudComputing } from "react-icons/tb";
import Slide from 'react-reveal/Slide';
function Cards2() {


    return (
        <div className='container  rounded-2xl bg-opacity-50 w-full h-fit lg:h-[700px] my-16 m-auto'>
            <div className='w-full h-full block lg:flex '>
                <Slide left>
                    <div className='w-1/2 m-auto lg:w-[25%] h-full block lg:flex px-4 py-10'>
                        <div className='w-full h-2/6 bg-white rounded-2xl shadow-lg  m-auto   '>
                            <img className='w-full h-full object-contain ' src='/images/aboutus/vision4.png' />
                        </div>
                    </div>
                </Slide>
                <Slide right cascade>
                    <div className='w-1/2 m-auto lg:w-[25%] h-full  flex justify-around  flex-col space-y-4'>
                        <div className=' w-full h-2/6 bg-white rounded-2xl shadow-lg px-4  '>
                            <img className='w-full h-full object-contain ' src='/images/a6/tech3.png' />
                        </div>
                        <div className='w-full h-2/6 bg-white rounded-2xl shadow-lg px-4    '>
                            <img className='w-full h-full object-contain ' src='/images/a6/Strategy.png' />
                        </div>
                    </div>
                </Slide>
                <Slide right cascade>
                    <div className='w-full lg:w-[50%] h-full p-2 lg:p-10 m-auto flex flex-col justify-center space-y-6 '>
                        <div className='flex gap-2'>

                            <h1 className='text-neutral-500 m-auto font-poppins text-base  font-normal leading-8 sm:leading-8 tracking-wide'>
                                At TASPro, we strongly focus on business outcome, thus we treat technology as a value driver rather as constraint.
                            </h1>
                        </div>
                        <div className='flex gap-2'>

                            <h1 className='text-neutral-500 m-auto font-poppins text-base  font-normal leading-8 sm:leading-8 tracking-wide'>
                                We provide technical solution that are customized and specific to the business needs.
                            </h1>
                        </div>
                        <div className='flex gap-2'>

                            <h1 className='text-neutral-500 m-auto font-poppins text-base  font-normal leading-8 sm:leading-8  tracking-wide'>
                                Our business focused technology ranges from Enterprise Business System,application customer experience & engagement, Data management & practices, Decision support systems, Legislation & compliance, Cyber security and Virtual desktop support.
                            </h1>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    )
}

export default Cards2