import LightGallery from 'lightgallery/react';
import React from 'react';
// import styles
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lightgallery.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import { townhall2023 } from '../asset/Townhall2023';

function Gallery() {

    const gallery = townhall2023;

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    const onBeforeSlide = (detail) => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    return (
        <>
            <div className='w-full  bg-[#f0f0f0] pl-28 pr-28'>

                <div className='flex   justify-between flex-wrap gap-3 bg-[#f0f0f0] py-12'>
                    {
                        gallery?.map((img) => {

                            return (
                                <>
                                    <div className=" rounded-lg flex" key={img.id} >
                                        <LightGallery
                                            onInit={onInit}
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                            onBeforeSlide={onBeforeSlide}


                                        >
                                            <a href={img.src}>
                                                <img loading='lazy' alt={img.name} src={img.thumb} className='w-[250px] h-[200px] object-cover rounded-lg ' />
                                            </a>
                                        </LightGallery>
                                    </div>
                                </>
                            )
                        })

                    }
                </div>
            </div>
        </>
    );
}


export default Gallery