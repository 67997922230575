import { ArchiveBoxXMarkIcon, ArrowDownOnSquareStackIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import './style.css';
import Fade from 'react-reveal/Fade';

function Hero() {
  return (
    <div className='w-full h-[650px] relative -mt-[80px] bg-hero3  bottom bg-cover bg-center overflow-hidden 
    ' >
      {/* <div className=' w-full h-[650px] relative opacity-50
      bg-gradient-to-br from-[#5dc4ff] to-transparent
      blur-[100px] backdrop-blur-[5px] backdrop-brightness-0
      
      '>
      </div> */}
        {/* bg-gradient-to-b from-gray-900 to-gray-600  */}

      <Fade left cascade >
        <div className='z-10 bg-[#10426b] h-full bg-opacity-30  flex '>
          {/* <h4 className='text-lg font-light  leading-relaxed text-white  text-start animate-fadeInLeft sm:text-2xl'>Delivering  
            <h1 className='text-[#ffffff] text-4xl  sm:text-6xl  tracking-wider  flex  -ml-28  justify-center   font-bold'>business</h1>
             <br />
              value through<br /> <span className='text-[#ffffff] text-4xl  sm:text-6xl  tracking-wider  flex  justify-center   font-bold '>Technology.</span>
          </h4> */}
          <div className=' h-full w-full  lg:w-5/6 mt-[150px] text-white p-12  xl:pl-12 lg:pl-0 justify-center  m-auto text-center md:items-center  flex flex-col  sm:leading-[48px] leading-8 '>
          <h1 className='font-light sm:text-[32px] text-[15px] opacity-80 '>Delivering</h1>
          <h1 className='font-bold sm:text-[52px] text-[32px] '>Business <span className='font-light sm:text-[32px] text-[15px] '>Value through</span></h1> 
          <h1 className='sm:text-[85px] text-[52px]  '>Technology</h1>
          <div className='mt-8'><button className='w-[130px] h-[45px] bg-[#effffd] text-gray-800 rounded-md  font-semibold text-lg animate-fadeInDown animate-delay-500 hover:bg-[#10426b] hover:scale-105 transition hover:ease-in-out duration-300 hover:text-white'><a href="/contact" className=''>Contact</a> </button></div>
          </div>
        </div>
      </Fade>

      {/* <div className=" items-center justify-end lg:flex lg:flex-1 lg:w-0">

        <a
          href="/contact"
          className="font-poppins font-semibold ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#10426b] px-4 py-2 text-base  text-white shadow-sm hover:bg-[#10426b]"
        >
          Contact us
        </a>
      </div> */}


      {/* <Fade top cascade>
        <div className='flex  absolute top-[580px] sm:top-[430px] justify-center sm:justify-start sm:ml-[15%] w-full '>
          <button className='w-[130px] h-[45px] bg-[#effffd] text-gray-700 rounded-md  font-semibold text-lg animate-fadeInDown animate-delay-500'><a href="/contact">Contact</a> </button>
          <ArrowLeftIcon className='animate-fadeInDown animate-delay-500 w-[30px] ml-1   text-white ' />
        </div>
      </Fade> */}
    </div >
  )
}

export default Hero