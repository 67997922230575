// import img1 from '../asset/gallery/townhall1_2023/1.jpg';
// import img10 from '../asset/gallery/townhall1_2023/10.jpg';
// import img11 from '../asset/gallery/townhall1_2023/11.jpg';
// import img12 from '../asset/gallery/townhall1_2023/12.jpg';
// import img13 from '../asset/gallery/townhall1_2023/13.jpg';
// import img14 from '../asset/gallery/townhall1_2023/14.jpg';
// import img15 from '../asset/gallery/townhall1_2023/15.jpg';
// import img16 from '../asset/gallery/townhall1_2023/16.jpg';
// import img17 from '../asset/gallery/townhall1_2023/17.jpg';
// import img18 from '../asset/gallery/townhall1_2023/18.jpg';
// import img19 from '../asset/gallery/townhall1_2023/19.jpg';
// import img2 from '../asset/gallery/townhall1_2023/2.jpg';
// import img20 from '../asset/gallery/townhall1_2023/20.jpg';
// import img21 from '../asset/gallery/townhall1_2023/21.jpg';
// import img22 from '../asset/gallery/townhall1_2023/22.jpg';
// import img23 from '../asset/gallery/townhall1_2023/23.jpg';
// import img24 from '../asset/gallery/townhall1_2023/24.jpg';
// import img25 from '../asset/gallery/townhall1_2023/25.jpg';
// import img26 from '../asset/gallery/townhall1_2023/26.jpg';
// import img27 from '../asset/gallery/townhall1_2023/27.jpg';
// import img28 from '../asset/gallery/townhall1_2023/28.jpg';
// import img29 from '../asset/gallery/townhall1_2023/29.jpg';
// import img3 from '../asset/gallery/townhall1_2023/3.jpg';
// import img30 from '../asset/gallery/townhall1_2023/30.jpg';
// import img31 from '../asset/gallery/townhall1_2023/31.jpg';
// import img32 from '../asset/gallery/townhall1_2023/32.jpg';
// import img33 from '../asset/gallery/townhall1_2023/33.jpg';
// import img34 from '../asset/gallery/townhall1_2023/34.jpg';
// import img35 from '../asset/gallery/townhall1_2023/35.jpg';
// import img36 from '../asset/gallery/townhall1_2023/36.jpg';
// import img37 from '../asset/gallery/townhall1_2023/37.jpg';
// import img39 from '../asset/gallery/townhall1_2023/39.jpg';
// import img4 from '../asset/gallery/townhall1_2023/4.jpg';
// import img40 from '../asset/gallery/townhall1_2023/40.jpg';
// import img41 from '../asset/gallery/townhall1_2023/41.jpg';
// import img42 from '../asset/gallery/townhall1_2023/42.jpg';
// import img5 from '../asset/gallery/townhall1_2023/5.jpg';
// import img6 from '../asset/gallery/townhall1_2023/6.jpg';
// import img7 from '../asset/gallery/townhall1_2023/7.jpg';
// import img8 from '../asset/gallery/townhall1_2023/8.jpg';
// import img9 from '../asset/gallery/townhall1_2023/9.jpg';
// //-------------------------
// import img43 from '../asset/gallery/townhall1_2023/1.1.JPG';
// import img44 from '../asset/gallery/townhall1_2023/1.2.JPG';
// import img45 from '../asset/gallery/townhall1_2023/1.3.JPG';
// import img46 from '../asset/gallery/townhall1_2023/1.4.JPG';
// import img47 from '../asset/gallery/townhall1_2023/1.5.JPG';
// import img48 from '../asset/gallery/townhall1_2023/1.6.JPG';
// --------------------------
import img1 from '../asset/00_SALIM1.jpg';
import img10 from '../asset/00_SALIM10.jpg';
import img2 from '../asset/00_SALIM2.jpg';
import img3 from '../asset/00_SALIM3.jpg';
import img4 from '../asset/00_SALIM4.jpg';
import img5 from '../asset/00_SALIM5.jpg';
import img6 from '../asset/00_SALIM6.jpg';
import img7 from '../asset/00_SALIM7.jpg';
import img8 from '../asset/00_SALIM8.jpg';
import img9 from '../asset/00_SALIM9.jpg';
import img11 from '../asset/1_AKASH.jpg';
import img12 from '../asset/1_DHEENA.jpg';
import img13 from '../asset/1_DURGA.jpg';
import img14 from '../asset/1_GOPI.jpg';
import img15 from '../asset/1_HUSSAIN.jpg';
// import img16 from '../asset/1_JOHN.jpg';
import img17 from '../asset/1_KRISH.jpg';
import img18 from '../asset/1_MAHAPRABHU.jpg';
import img19 from '../asset/1_NATTUDURAI.jpg';
import img20 from '../asset/1_NAVEENA.jpg';
import img21 from '../asset/1_RAJ.jpg';
import img22 from '../asset/1_SARAVANA.jpg';
import img23 from '../asset/1_SATHISH.jpg';
import img24 from '../asset/1_VEL.jpg';
import img25 from '../asset/1_VIGNESHNC.jpg';
import img26 from '../asset/1_VIGNESHSB.jpg';
import img27 from '../asset/2_ASIK_1.jpg';
import img28 from '../asset/2_ASIK_2.jpg';
import img29 from '../asset/2_ASIK_3.jpg';
import img30 from '../asset/2_JOHN_1.jpg';
import img31 from '../asset/2_JOHN_2.jpg';
import img32 from '../asset/2_JOHN_3.jpg';
import img33 from '../asset/2_NC_1.jpg';
import img34 from '../asset/2_NC_2.jpg';
import img35 from '../asset/2_NC_3.jpg';
import img36 from '../asset/2_SAR_1.jpg';
import img37 from '../asset/2_SAR_2.jpg';
import img38 from '../asset/2_SAR_3.jpg';
import img39 from '../asset/2_SB_1.jpg';
import img40 from '../asset/2_SB_2.jpg';
import img41 from '../asset/2_SB_3.jpg';
import img42 from '../asset/3_TEAM_0.jpg';
import img43 from '../asset/3_TEAM_1.jpg';
import img52 from '../asset/3_TEAM_10.jpg';
import img53 from '../asset/3_TEAM_11.jpg';
import img54 from '../asset/3_TEAM_12.jpg';
import img55 from '../asset/3_TEAM_13.jpg';
import img56 from '../asset/3_TEAM_14.jpg';
import img57 from '../asset/3_TEAM_15.jpg';
import img58 from '../asset/3_TEAM_16.jpg';
import img59 from '../asset/3_TEAM_17.jpg';
import img60 from '../asset/3_TEAM_18.jpg';
import img61 from '../asset/3_TEAM_19.jpg';
import img44 from '../asset/3_TEAM_2.jpg';
import img62 from '../asset/3_TEAM_20.jpg';
import img63 from '../asset/3_TEAM_21.jpg';
import img64 from '../asset/3_TEAM_22.jpg';
import img65 from '../asset/3_TEAM_23.jpg';
import img66 from '../asset/3_TEAM_24.jpg';
import img67 from '../asset/3_TEAM_25.jpg';
import img68 from '../asset/3_TEAM_26.jpg';
import img69 from '../asset/3_TEAM_27.jpg';
import img70 from '../asset/3_TEAM_28.jpg';
import img45 from '../asset/3_TEAM_3.jpg';
import img71 from '../asset/3_TEAM_30.jpg';
import img72 from '../asset/3_TEAM_31.jpg';
import img73 from '../asset/3_TEAM_32.jpg';
import img74 from '../asset/3_TEAM_33.jpg';
import img75 from '../asset/3_TEAM_35.jpg';
import img76 from '../asset/3_TEAM_36.jpg';
import img77 from '../asset/3_TEAM_37.jpg';
import img78 from '../asset/3_TEAM_39.jpg';
import img46 from '../asset/3_TEAM_4.jpg';
import img79 from '../asset/3_TEAM_41.jpg';
import img80 from '../asset/3_TEAM_42.jpg';
import img81 from '../asset/3_TEAM_43.jpg';
import img82 from '../asset/3_TEAM_44.jpg';
import img83 from '../asset/3_TEAM_45.jpg';
import img47 from '../asset/3_TEAM_5.jpg';
import img48 from '../asset/3_TEAM_6.jpg';
import img49 from '../asset/3_TEAM_7.jpg';
import img50 from '../asset/3_TEAM_8.jpg';
import img51 from '../asset/3_TEAM_9.jpg';
import img84 from '../asset/4_EVT_1.jpg';
import img85 from '../asset/4_EVT_2.jpg';
import img86 from '../asset/4_EVT_3.jpg';
import img87 from '../asset/ART.jpg';






export const townhall2023 = [
    { id: '1', size: '1400-800', src: img1, thumb: img1, name: '00_SALIM1' },
    { id: '2', size: '1400-800', src: img2, thumb: img2, name: '00_SALIM2' },
    { id: '3', size: '1400-800', src: img3, thumb: img3, name: '00_SALIM3' },
    { id: '4', size: '1400-800', src: img4, thumb: img4, name: '00_SALIM4' },
    { id: '5', size: '1400-800', src: img5, thumb: img5, name: '00_SALIM5' },
    { id: '6', size: '1400-800', src: img6, thumb: img6, name: '00_SALIM6' },
    { id: '7', size: '1400-800', src: img7, thumb: img7, name: '00_SALIM7' },
    { id: '8', size: '1400-800', src: img8, thumb: img8, name: '00_SALIM8' },
    { id: '9', size: '1400-800', src: img9, thumb: img9, name: '00_SALIM9' },
    { id: '10', size: '1400-800', src: img10, thumb: img10, name: '00_SALIM10' },
    { id: '21', size: '1400-800', src: img21, thumb: img21, name: '1_RAJ' },
    { id: '23', size: '1400-800', src: img23, thumb: img23, name: '1_SATHISH' },
    { id: '24', size: '1400-800', src: img24, thumb: img24, name: '1_VEL' },
    { id: '11', size: '1400-800', src: img11, thumb: img11, name: '1_AKASH' },
    { id: '12', size: '1400-800', src: img12, thumb: img12, name: '1_DHEENA' },
    { id: '13', size: '1400-800', src: img13, thumb: img13, name: '1_DURGA' },
    { id: '14', size: '1400-800', src: img14, thumb: img14, name: '1_GOPI' },
    { id: '15', size: '1400-800', src: img15, thumb: img15, name: '1_HUSSAIN' },
    // { id: '16', size: '1400-800', src: img16, thumb: img16, name: '1_JOHN' },
    { id: '17', size: '1400-800', src: img17, thumb: img17, name: '1_KRISH' },
    { id: '18', size: '1400-800', src: img18, thumb: img18, name: '1_MAHAPRABHU' },
    { id: '19', size: '1400-800', src: img19, thumb: img19, name: '1_NATTUDURAI' },
    { id: '20', size: '1400-800', src: img20, thumb: img20, name: '1_NAVEENA' },
    { id: '22', size: '1400-800', src: img22, thumb: img22, name: '1_SARAVANA' },
    { id: '25', size: '1400-800', src: img25, thumb: img25, name: '1_VIGNESHNC' },
    { id: '26', size: '1400-800', src: img26, thumb: img26, name: '1_VIGNESHSB' },
    { id: '27', size: '1400-800', src: img27, thumb: img27, name: '2_ASIK_1' },
    { id: '28', size: '1400-800', src: img28, thumb: img28, name: '2_ASIK_2' },
    { id: '29', size: '1400-800', src: img29, thumb: img29, name: '2_ASIK_3' },
    { id: '30', size: '1400-800', src: img30, thumb: img30, name: '2_JOHN_1' },
    { id: '31', size: '1400-800', src: img31, thumb: img31, name: '2_JOHN_2' },
    { id: '32', size: '1400-800', src: img32, thumb: img32, name: '2_JOHN_3' },
    { id: '33', size: '1400-800', src: img33, thumb: img33, name: '2_NC_1' },
    { id: '34', size: '1400-800', src: img34, thumb: img34, name: '2_NC_2' },
    { id: '35', size: '1400-800', src: img35, thumb: img35, name: '2_NC_3' },
    { id: '36', size: '1400-800', src: img36, thumb: img36, name: '2_SAR_1' },
    { id: '37', size: '1400-800', src: img37, thumb: img37, name: '2_SAR_2' },
    { id: '38', size: '1400-800', src: img38, thumb: img38, name: '2_SAR_3' },
    { id: '39', size: '1400-800', src: img39, thumb: img39, name: '2_SB_1' },
    { id: '40', size: '1400-800', src: img40, thumb: img40, name: '2_SB_2' },
    { id: '41', size: '1400-800', src: img41, thumb: img41, name: '2_SB_3' },
    { id: '42', size: '1400-800', src: img42, thumb: img42, name: '3_TEAM_0' },
    { id: '43', size: '1400-800', src: img43, thumb: img43, name: '3_TEAM_1' },
    { id: '44', size: '1400-800', src: img44, thumb: img44, name: '3_TEAM_2' },
    { id: '45', size: '1400-800', src: img45, thumb: img45, name: '3_TEAM_3' },
    { id: '46', size: '1400-800', src: img46, thumb: img46, name: '3_TEAM_4' },
    { id: '47', size: '1400-800', src: img47, thumb: img47, name: '3_TEAM_5' },
    { id: '48', size: '1400-800', src: img48, thumb: img48, name: '3_TEAM_6' },
    { id: '49', size: '1400-800', src: img49, thumb: img49, name: '3_TEAM_7' },
    { id: '50', size: '1400-800', src: img50, thumb: img50, name: '3_TEAM_8' },
    { id: '51', size: '1400-800', src: img51, thumb: img51, name: '3_TEAM_9' },
    { id: '52', size: '1400-800', src: img52, thumb: img52, name: '3_TEAM_10' },
    { id: '53', size: '1400-800', src: img53, thumb: img53, name: '3_TEAM_11' },
    { id: '54', size: '1400-800', src: img54, thumb: img54, name: '3_TEAM_12' },
    { id: '55', size: '1400-800', src: img55, thumb: img55, name: '3_TEAM_13' },
    { id: '56', size: '1400-800', src: img56, thumb: img56, name: '3_TEAM_14' },
    { id: '57', size: '1400-800', src: img57, thumb: img57, name: '3_TEAM_15' },
    { id: '58', size: '1400-800', src: img58, thumb: img58, name: '3_TEAM_16' },
    { id: '59', size: '1400-800', src: img59, thumb: img59, name: '3_TEAM_17' },
    { id: '60', size: '1400-800', src: img60, thumb: img60, name: '3_TEAM_18' },
    { id: '61', size: '1400-800', src: img61, thumb: img61, name: '3_TEAM_19' },
    { id: '62', size: '1400-800', src: img62, thumb: img62, name: '3_TEAM_20' },
    { id: '63', size: '1400-800', src: img63, thumb: img63, name: '3_TEAM_21' },
    { id: '64', size: '1400-800', src: img64, thumb: img64, name: '3_TEAM_22' },
    { id: '65', size: '1400-800', src: img65, thumb: img65, name: '3_TEAM_23' },
    { id: '66', size: '1400-800', src: img66, thumb: img66, name: '3_TEAM_24' },
    { id: '67', size: '1400-800', src: img67, thumb: img67, name: '3_TEAM_25' },
    { id: '68', size: '1400-800', src: img68, thumb: img68, name: '3_TEAM_26' },
    { id: '69', size: '1400-800', src: img69, thumb: img69, name: '3_TEAM_27' },
    { id: '70', size: '1400-800', src: img70, thumb: img70, name: '3_TEAM_28' },
    { id: '71', size: '1400-800', src: img71, thumb: img71, name: '3_TEAM_30' },
    { id: '72', size: '1400-800', src: img72, thumb: img72, name: '3_TEAM_31' },
    { id: '73', size: '1400-800', src: img73, thumb: img73, name: '3_TEAM_32' },
    { id: '74', size: '1400-800', src: img74, thumb: img74, name: '3_TEAM_33' },
    { id: '75', size: '1400-800', src: img75, thumb: img75, name: '3_TEAM_35' },
    { id: '76', size: '1400-800', src: img76, thumb: img76, name: '3_TEAM_36' },
    { id: '77', size: '1400-800', src: img77, thumb: img77, name: '3_TEAM_37' },
    { id: '78', size: '1400-800', src: img78, thumb: img78, name: '3_TEAM_39' },
    { id: '79', size: '1400-800', src: img79, thumb: img79, name: '3_TEAM_41' },
    { id: '80', size: '1400-800', src: img80, thumb: img80, name: '3_TEAM_42' },
    { id: '81', size: '1400-800', src: img81, thumb: img81, name: '3_TEAM_43' },
    { id: '82', size: '1400-800', src: img82, thumb: img82, name: '3_TEAM_44' },
    { id: '83', size: '1400-800', src: img83, thumb: img83, name: '3_TEAM_45' },
    { id: '84', size: '1400-800', src: img84, thumb: img84, name: '4_EVT_1' },
    { id: '85', size: '1400-800', src: img85, thumb: img85, name: '4_EVT_2' },
    { id: '86', size: '1400-800', src: img86, thumb: img86, name: '4_EVT_3' },
    { id: '87', size: '1400-800', src: img87, thumb: img87, name: 'ART' },



]